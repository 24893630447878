/* src/App.js */
import React, { useEffect, useState } from "react"
import { Amplify, API, graphqlOperation } from "aws-amplify"
import { createTodo } from "./graphql/mutations"
import { listTodos } from "./graphql/queries"
import {
  withAuthenticator,
  Button,
  Heading,
  Text,
  TextField,
  View,
} from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"

import awsExports from "./aws-exports"
Amplify.configure(awsExports)

const initialState = { name: "", description: "" }

const App = ({ signOut, user }) => {
  const [formState, setFormState] = useState(initialState)
  const [todos, setTodos] = useState([])

  useEffect(() => {
    fetchTodos()
  }, [])

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }

  async function fetchTodos() {
    try {
      const todoData = await API.graphql(graphqlOperation(listTodos))
      const todos = todoData.data.listTodos.items
      setTodos(todos)
    } catch (err) {
      console.log("error fetching todos")
    }
  }

  async function addTodo() {
    try {
      if (!formState.name || !formState.description) return
      const todo = { ...formState }
      setTodos([...todos, todo])
      setFormState(initialState)
      await API.graphql(graphqlOperation(createTodo, { input: todo }))
    } catch (err) {
      console.log("error creating todo:", err)
    }
  }

  return (
    <View style={styles.container}>
      <Heading style={styles.header} level={1}>
        Hello {user.username}
      </Heading>
      <Button style={styles.signOut} onClick={signOut}>
        Sign out
      </Button>
      <Heading style={styles.header} level={2}>
        Welcome to my cheeky to do list
      </Heading>
      <TextField
        placeholder="Name"
        onChange={(event) => setInput("name", event.target.value)}
        style={styles.input}
        defaultValue={formState.name}
      />
      <TextField
        placeholder="Description"
        onChange={(event) => setInput("description", event.target.value)}
        style={styles.input}
        defaultValue={formState.description}
      />
      <Button style={styles.button} onClick={addTodo}>
        Create Todo
      </Button>
      {todos.map((todo, index) => (
        <View key={todo.id ? todo.id : index} style={styles.todo}>
          <Text style={styles.todoName}>{todo.name}</Text>
          <Text style={styles.todoDescription}>{todo.description}</Text>
        </View>
      ))}
    </View>
  )
}

const styles = {
  container: {
    width: 800,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
    color: "#fefee3",
  },
  todo: { margin: 15 },
  input: {
    border: "none",
    backgroundColor: "#fefee3",
    marginBottom: 10,
    padding: 8,
    fontSize: 18,
  },
  todoName: { color: "#fefee3", fontSize: 20, fontWeight: "bold" },
  todoDescription: { color: "#fefee3", marginBottom: 0 },
  button: {
    backgroundColor: "#4c956c",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
  },
  signOut: {
    width: "100px",
  },
  header: {
    color: "#fefee3",
    padding: "20px",
  },
}

export default withAuthenticator(App)
